import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer>
        <p>
          Powered by <a href="https://jjuslin03.github.io">Juslin</a>
        </p>
      </footer>
    );
  }
}

export default Footer;
